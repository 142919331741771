<template>
  <el-row>
    <div class="main-width-80">
      <div class="shop-list-title">
        <h1>Shop</h1>
        <p>
          Whether you are shopping within Singapore or overseas, we got all your
          shopping needs covered.
        </p>
      </div>
      <ul class="shop-list">
        <li v-for="item in 4" :key="item">
          <h2>Stamps and Gifts</h2>
          <div class="img-div">
            <img
              src="https://www.singpost.com/sites/default/files/2020-08/FF-2805-46.jpg"
              alt=""
            />
            <a class="overlay-text" href=""
              >Browse & Shop{{ item }}
              <i class="el-icon-arrow-right icon-jt"></i>
            </a>
          </div>

          <p>
            Whether you are shopping within Singapore or overseas, we got all
            your shopping needs covered.
          </p>
        </li>
      </ul>
      <div class="related-content-block">
        <h3>You might also be interested in</h3>
        <el-row style="height: 180px">
          <el-col :span="8">
            <a href="" class="media-image">
              <img
                src="https://www.singpost.com/sites/default/files/2020-08/cross-sell-shop-popstation.png"
                alt=""
              />
            </a>
          </el-col>
          <el-col :span="16" class="col-16">
            <div class="media-body">
              <h5 class="mt-0">
                <a
                  href="https://www.speedpost.com.sg/what-we-offer/POPStation.html"
                  tabindex="-1"
                  >Collect via POPStation</a
                >
              </h5>

              <div class="small-gap">
                <p>
                  Shop online with our retail partners such as Xiaomi, Zalora
                  and Taobao, and have your items delivered to a POPStation near
                  you! Collect your shopping loots 24/7 - at your own
                  convenience!
                </p>
                <div
                  class="text-right"
                
                >
                  <img
                    alt=""
                    src="https://www.singpost.com/sites/default/files/2020-09/logo-popstation.png"
                  />
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </el-row>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
 @import "../assets/less/common.less";

.shop-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    width: 32%;
    margin-right: 1%;
    margin-bottom: 25px;

    h2 {
      font-size: 22px;
      font-weight: 600;
      color: #24549d;
      text-align: left;
      font-weight: 500;
      margin-bottom: 15px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #24549d;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      text-align: left;
    }
    .img-div {
      position: relative;
      img {
        width: 100%;
      }
      .overlay-text {
        background: #315599;
        display: flex;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        vertical-align: middle;
        z-index: 5;
        font-weight: 600;
        opacity: 0;
        letter-spacing: 0.5px;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        .icon-jt {
          padding-top: 5px;
          font-weight: bold;
          font-size: 18px;
        }
      }
      &:hover .overlay-text {
        opacity: 0.8;
      }
    }
  }
}
.shop-list-title {
  text-align: left;
  margin-bottom: 10px;
  h1 {
    font-weight: 300;
    font-style: normal;
    color: #24549d;
    font-size: 30px;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
  }
}
.related-content-block {
  h3 {
    padding-top: 40px;
    padding-bottom: 20px;
    text-align: left;
    font-size: 26px;
    font-weight: 500;
    color: #666;
  }
  .media-image {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .col-16 {
    border-right: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    height: 180px;
    .media-body {
      padding-left: 15px;
      .mt-0 {
        text-align: left;
        padding-bottom: 15px;
        padding-top:10px;
        a {
          color: #3982ea;
          font-size: 23px;
          font-weight:500;
        }
      }
      .small-gap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        p {
          text-align: left;
          color: #333;
          font-size: 14px;
          font-weight: 600;
          width:80%;
        }
        .text-right{
            margin-right:10px;
            img{
                width:115px;
                height:23px;
            }
        }
      }
    }
  }
}
</style>
